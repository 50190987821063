
import Vue from '../VueBase'
import Component from 'vue-class-component'
import PhotographerJobList from './PhotographerJobList.vue'
import { Prop } from 'vue-property-decorator'
import { JobSnap, updatePhotographerJobStatusFromRef, updateBookingFromRef } from '@ht-lib/accounts-common'
import { BookingPhotographerStatus, PhotographerJobStatus } from '@ht-lib/accounts-models'
import { SimpleBooking } from '../bookings'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { PhotographerJobList },
  name: 'PhotographerJobDialog'
})
export default class extends Vue {
  @Prop({ required: true }) jobs!: JobSnap[]
  @Prop({ required: true, type: Object }) booking!: SimpleBooking

  get header (): string {
    const { accountName, arrivalDate } = this.booking.data
    return `${ accountName } - ${ arrivalDate }`
  }

  get isDoneWithBooking (): boolean {
    return this.booking.data.photographersMap[this.$auth.user.uid].status === BookingPhotographerStatus.COMPLETE
  }

  async finishedWithBooking (): Promise<void> {
    await Promise.all(
      this.jobs.map(async job => updatePhotographerJobStatusFromRef(job.ref, this.$auth.user.uid, PhotographerJobStatus.COMPLETE))
    )

    await updateBookingFromRef(this.booking.ref, {
      photographersMap: {
        [this.$auth.user.uid]: {
          status: BookingPhotographerStatus.COMPLETE
        }
      }
    })
  }

  async notDoneWithBooking (): Promise<void> {
    await updateBookingFromRef(this.booking.ref, {
      photographersMap: {
        [this.$auth.user.uid]: {
          status: BookingPhotographerStatus.ASSIGNED
        }
      }
    })
  }

  async uploadNewJob (): Promise<void> {
    await this.$router.push(`/booking/${ this.booking.id }/upload`)
  }
}
