
import Vue from 'vue'
import Component from 'vue-class-component'
import { BookingPhotographer } from '@ht-lib/accounts-models'
import { Prop } from 'vue-property-decorator'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { },
  name: 'SelectPhotographer'
})
export default class extends Vue {
  @Prop({ type: Array, required: false, default: [] }) photographers!: BookingPhotographer[]

  get hasPhotographers (): boolean { return this.photographers.length > 0 }

  created (): void {
    this.$emit('loaded')
  }
}
