
import Vue from 'vue'
import Component from 'vue-class-component'
import AccountJobList from './AccountJobList.vue'
import PhotographerBreakdown from './PhotographerBreakdown.vue'
import { Prop } from 'vue-property-decorator'
import { JobSnap, BookingSnap } from '@ht-lib/accounts-common'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { AccountJobList, PhotographerBreakdown },
  name: 'AccountJobDialog'
})
export default class extends Vue {
  @Prop({ required: true }) jobs!: JobSnap[]
  @Prop({ required: true, type: Object }) booking!: BookingSnap

  get header (): string {
    const { accountName, arrivalDate } = this.booking.data()
    return `${ accountName } - ${ arrivalDate }`
  }
}
