
import Vue from 'vue'
import Component from 'vue-class-component'
import { getAccountBookings } from '@ht-lib/accounts-common'
import { Booking, WithId } from '@ht-lib/accounts-models'
import { Prop } from 'vue-property-decorator'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { },
  name: 'BookingSearch'
})
export default class extends Vue {
  @Prop({ required: true, type: String }) accountCode!: string
  private bookings: Array<WithId<Booking>> = []
  input = ''

  created (): void {
    this.input = this.accountCode
  }

  get hasBookings (): boolean { return this.bookings.length > 0 }

  async findBooking (): Promise<void> {
    const bookingQuery = await getAccountBookings(this.input).get()
    console.log(`found ${ bookingQuery.size } documents for search ${ this.input }`)
    this.bookings = bookingQuery
      .docs
      .map(d => ({ id: d.id, ...d.data() }))
      .sort((prev, next) => prev.arrivalUnixTimestamp - next.arrivalUnixTimestamp)
  }
}
