
import { Component, Vue, Prop } from 'vue-property-decorator'
import { JobSnap, onlineJobTypes, AimsCodeWithDescription, updateJob } from '@ht-lib/accounts-common'
import { format } from 'date-fns'
import { Photographer, PhotographerJobStatus, JobStatus, Job } from '@ht-lib/accounts-models'
import { some } from 'lodash'

interface UploadButtonAction {
  label: string
  colour: string
  action: () => Promise<void>
}

@Component({
  name: 'AccountJobList'
})
export default class extends Vue {
  @Prop({ required: true, type: Array }) jobs!: JobSnap[]
  activeJob: JobSnap | null = null
  jobTypes: AimsCodeWithDescription[] | null = null
  async created (): Promise<void> {
    this.jobTypes = await onlineJobTypes()
  }

  jobTypeDescr (jobType: string): string {
    return this.jobTypes?.find(x => x.id === jobType)?.description ?? ''
  }

  async review (id: string): Promise<void> {
    await this.$router.push(`/worksheet/${ id }`)
  }

  totalImages (job: Job): number {
    return Object.values(job.photographersMap).reduce((sum, c) => {
      return c.imageCount + sum
    }, 0)
  }

  showReviewButton (job: Job): boolean {
    return job.status === JobStatus.IN_REVIEW || job.status === JobStatus.UPLOAD_LOCKED
  }

  preventUploadButton (jobSnap: JobSnap): UploadButtonAction | null {
    const job = jobSnap.data()
    if ((job.status === JobStatus.WAITING_FOR_PHOTOGRAPHERS || job.status === JobStatus.AWAITING_APPROVAL || job.status === JobStatus.DRAFT) && !this.isAnyPhotographerUploading(jobSnap)) {
      return {
        label: 'Prevent Uploads',
        colour: 'positive',
        action: async (): Promise<void> => {
          await updateJob(jobSnap.id, {
            status: JobStatus.UPLOAD_LOCKED
          })
        }
      }
    } else if (job.status === JobStatus.IN_REVIEW || job.status === JobStatus.UPLOAD_LOCKED) {
      return {
        label: 'Allow Uploads',
        colour: 'primary',
        action: async (): Promise<void> => {
          await updateJob(jobSnap.id, {
            status: JobStatus.WAITING_FOR_PHOTOGRAPHERS
          })
        }
      }
    }
    return null
  }

  isAnyPhotographerUploading (jobSnap: JobSnap): boolean {
    return some(jobSnap.data().photographersMap, p => p.status === PhotographerJobStatus.UPLOADING)
  }

  get activeJobType (): string {
    if (this.activeJob == null) return ''
    return this.jobTypeDescr(this.activeJob.data().jobType)
  }

  formatDate (iso: Date): string {
    return format(iso, 'dd MMM yyy')
  }
}

