
import { Component, Prop } from 'vue-property-decorator'
import { JobSnap, updatePhotographerJobStatus, onlineJobTypes, AimsCodeWithDescription } from '@ht-lib/accounts-common'
import { format } from 'date-fns'
import { PhotographerJobStatus, Job } from '@ht-lib/accounts-models'
import Vue from '../VueBase'

@Component({
  name: 'PhotographerJobList'
})
export default class extends Vue {
  @Prop({ required: true, type: Array }) jobs!: JobSnap[]

  jobTypes: AimsCodeWithDescription[] | null = null
  async created (): Promise<void> {
    this.jobTypes = await onlineJobTypes()
  }

  jobTypeDescr (jobType: string): string {
    return this.jobTypes?.find(x => x.id === jobType)?.description || 'Not found'
  }

  formatDate (iso: Date): string {
    return format(iso, 'dd MMM yyy')
  }

  imagesUploaded (job: Job): number {
    return job.photographersMap[this.$auth.user.uid].imageCount
  }

  get sortedJobs (): JobSnap[] {
    return this.jobs.sort((a, b) => this.getMyJobStatus(a.data()).localeCompare(this.getMyJobStatus(b.data())))
  }

  getMyJobStatus (job: Job): string {
    return job?.photographersMap[this.$auth.user.uid]?.status || ''
  }

  isDoneWithJob (job: Job): boolean {
    return this.getMyJobStatus(job) === PhotographerJobStatus.COMPLETE
  }

  jobRowColour (job: Job): string {
    if (this.isDoneWithJob(job)) {
      return 'bg-green-11'
    }
    return ''
  }

  async uploadToJob (jobId: string, bookingId: string): Promise<void> {
    await this.$router.push(`/booking/${ bookingId }/upload/${ jobId }`)
  }

  async finishedWithJob (jobId: string): Promise<void> {
    await updatePhotographerJobStatus(jobId, this.$auth.user.uid, PhotographerJobStatus.COMPLETE)
  }

  async notDoneWithJob (jobId: string): Promise<void> {
    await updatePhotographerJobStatus(jobId, this.$auth.user.uid, PhotographerJobStatus.AWAITING)
  }

  async reviewImages (jobId: string): Promise<void> {
    await this.$router.push(`/upload/qc/review/${ jobId }/grid`)
  }
}

