import { render, staticRenderFns } from "./PhotographerBookingList.vue?vue&type=template&id=16b6bde6&scoped=true&lang=pug"
import script from "./PhotographerBookingList.vue?vue&type=script&lang=ts"
export * from "./PhotographerBookingList.vue?vue&type=script&lang=ts"
import style0 from "./PhotographerBookingList.vue?vue&type=style&index=0&id=16b6bde6&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b6bde6",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QCard,QMarkupTable,QDialog});
