import { render, staticRenderFns } from "./BookingSearch.vue?vue&type=template&id=576eb2ce&lang=pug"
import script from "./BookingSearch.vue?vue&type=script&lang=ts"
export * from "./BookingSearch.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSpace,QBtn,QInput,QMarkupTable});qInstall(component, 'directives', {ClosePopup});
