import { render, staticRenderFns } from "./PhotographerJobList.vue?vue&type=template&id=64c5c855&lang=pug"
import script from "./PhotographerJobList.vue?vue&type=script&lang=ts"
export * from "./PhotographerJobList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMarkupTable,QBtn});
